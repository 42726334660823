import React, { useEffect, useState } from 'react';
import AOS from 'aos'; // AOS 임포트
import 'aos/dist/aos.css'; // AOS CSS 임포트
import '../styles/service.css'
import '../styles/header.css'
import '../styles/common.css'
import '../styles/reset.css'
import '../styles/topbtn.css'
import Header from '../components/Header';
import FooterEn from "../components/eng/FooterEn";
import DantaroEn from "../components/eng/DantaroEn";
import MougoEn from "../components/eng/MougoEn";
import DfiansEn from "../components/eng/DfiansEn";
import BonegoEn from "../components/eng/BonegoEn";
import Buligoen from "../components/eng/Buligoen";
import ChartEn from "../components/eng/ChartEn";
import SiriusEn from "../components/eng/SiriusEn";
import ContactUsEn from "../components/eng/ContactUsEn";

const Service = () => {
    const [openMenu, setOpenMenu] = useState(false);
    const [scrollActive, setScrollActive] = useState(false);

    useEffect(() => {
        AOS.init();
        const handleScroll = () => {
          setScrollActive(window.scrollY > 800);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
      }, []);

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const down_sr = () => {
        scrollToSection('serviceSection');
    };

    const down_tr = () => {
        scrollToSection('chartSection');
    };

    const down_al = () => {
        scrollToSection('siriusSection');
    };

    const toTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };
    

    return (
        <>
            <Header openMenu={openMenu} setOpenMenu={setOpenMenu} />
            <div id="servicemainVisual">
                <div className="servicemainSlider">
                    <div className="msImg"></div>
                    <div className="tbox">
                        <h4>Service</h4>
                    </div>
                </div>
            </div>
            <div id="container" class="service_en">
                <section className="moeugo">
                    <div className="btndiv">
                        <button className="btn1" onClick={down_sr}>Service</button>
                        <button className="btn2" onClick={down_tr}>Trading system</button>
                        <button className="btn3" onClick={down_al}>Algorithm</button>
                    </div>
                    <div className="section_title_box">
                        <p className="section_title">
                            Service
                        </p>
                    </div>
                    <DantaroEn />
                    <MougoEn />
                    <DfiansEn />
                    <BonegoEn />
                    <Buligoen />
                </section>
                <ChartEn id="chartSection"/>
                <SiriusEn id="siriusSection"/>
            </div>
            <ContactUsEn />
            <FooterEn />
            <button
                className={`btn_top_display ${scrollActive ? 'on' : ''}`}
                onClick={toTop}
            />
        </>
    );
};

export default Service;