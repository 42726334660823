import React from 'react';

const AffiliateEnSection = () => {
  return (
    <section className="affiliate_section">
      <div className="inner">
        <div className="affiliate_img">
        </div>
        <h4 className="section_title">Affiliate</h4>
        <div className="aff_wrap">
          <div className="left_logo"></div>
          <div className="right_text">
            <h4 className="aff_title">DFians</h4>
            <p className="aff_subtitle">better value to your life.</p>
            <p className="aff_text">
                GMC Labs provides you with financial services based on trading<br class="mobi_none" />
                systems.Become a global leader in digital asset investment services and<br class="mobi_none" />
                take the lead in popularizing investment services so that they can make<br class="mobi_none" />
                stable investments with sufficient investment assets.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AffiliateEnSection;