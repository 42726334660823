// src/components/Sirius.js
import React from 'react';
import '../styles/Sirius.css'; // 스타일 파일을 따로 만들어서 import 하세요.
import trickCheck from '../assets/images/trick_check.svg'

const Sirius = ({ id }) => {
    return (
        <section className="sirius" id={id}>
            <div className="section_title_box">
                <p className="section_title">Algorithm</p>
            </div>
            <div className="gray_box">
                <div className="leoalpha_div">
                    <p className="leoalpha_font">레오알파 알고리즘</p>
                    <p className="leoalpha_boxfont">특허출원완료</p>
                </div>
                <p className="big-p">
                    빅데이터 분석과 퀀트 전략 기반 차트 지표 분석에 의한 알고리즘
                </p>
                <div className="leoalpha_div2">
                    <div className="leoalpha_flex">
                        <img src={trickCheck} alt="체크 아이콘" />
                        <p className="line-m">상승과 하락을 구분하여 매매 시점을 판단</p>
                    </div>
                    <div className="leoalpha_flex">
                        <img src={trickCheck} alt="체크 아이콘" />
                        <p className="line-m">현물시장과 마진시장에서 사용할 수 있는 알고리즘으로 구분</p>
                    </div>
                    <div className="leoalpha_flex">
                        <img src={trickCheck} alt="체크 아이콘" />
                        <p className="line-m">차트로 형성되는 모든 재테크 시장에 적용 가능</p>
                    </div>
                </div>
            </div>
            <div className="flexcontainer">
                <div className="box1 aos-init" data-aos="zoom-in-up" data-aos-duration="1500">
                    <div className="in">
                        <p className="title-box">Leo Alpha K</p>
                        <p className="title-sub">현물거래전용</p>
                        <div className="inbox-line">
                            <div className="inbox-flex">
                                <p className="inbox-p">총 누적 수익률</p>
                                <p className="inbox-num">453.58%</p>
                            </div>
                            <div className="inbox-flex">
                                <p className="inbox-p">연 환산 수익률</p>
                                <p className="inbox-num">113.40%</p>
                            </div>
                            <div className="inbox-flex">
                                <p className="inbox-p">MDD</p>
                                <p className="inbox-num">8.44%</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="box2 aos-init" data-aos="zoom-in-up" data-aos-duration="1500">
                    <div className="in">
                        <p className="title-box">Leo Alpha BM1</p>
                        <p className="title-sub">선물거래전용</p>
                        <div className="inbox-line">
                            <div className="inbox-flex">
                                <p className="inbox-p">총 누적 수익률</p>
                                <p className="inbox-num">308.62%</p>
                            </div>
                            <div className="inbox-flex">
                                <p className="inbox-p">연 환산 수익률</p>
                                <p className="inbox-num">77.16%</p>
                            </div>
                            <div className="inbox-flex">
                                <p className="inbox-p">MDD</p>
                                <p className="inbox-num">16.22%</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="box3 aos-init" data-aos="zoom-in-up" data-aos-duration="1500">
                    <div className="in">
                        <p className="title-box">Leo Alpha BM2</p>
                        <p className="title-sub">선물거래전용</p>
                        <div className="inbox-line">
                            <div className="inbox-flex">
                                <p className="inbox-p">총 누적 수익률</p>
                                <p className="inbox-num">461.21%</p>
                            </div>
                            <div className="inbox-flex">
                                <p className="inbox-p">연 환산 수익률</p>
                                <p className="inbox-num">115.30%</p>
                            </div>
                            <div className="inbox-flex">
                                <p className="inbox-p">MDD</p>
                                <p className="inbox-num">6.93%</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="box4 aos-init" data-aos="zoom-in-up" data-aos-duration="1500">
                    <div className="in">
                        <p className="title-box">Leo Alpha BM3</p>
                        <p className="title-sub">선물거래전용</p>
                        <div className="inbox-line">
                            <div className="inbox-flex">
                                <p className="inbox-p">총 누적 수익률</p>
                                <p className="inbox-num">454.25%</p>
                            </div>
                            <div className="inbox-flex">
                                <p className="inbox-p">연 환산 수익률</p>
                                <p className="inbox-num">113.56%</p>
                            </div>
                            <div className="inbox-flex">
                                <p className="inbox-p">MDD</p>
                                <p className="inbox-num">12.18%</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <p className="flexcontainer_underp">
                위 수익률은 2018.01.01 ~ 2021.12.31까지의 결과입니다.
            </p>
            <div className="service-box2">
                <p className="leoalpha_font">추세분석 알고리즘</p>
                <p className="big-p">차트 추세를 분석하고 적절한 간격으로 매매 지점을 판단하는 알고리즘</p>
                <div className="leoalpha_div2">
                    <div className="leoalpha_flex">
                        <img src={trickCheck} alt="체크 아이콘" />
                        <p className="line-m">차트 데이터를 참고하여 상승폭과 하락폭의 범위 분석</p>
                    </div>
                    <div className="leoalpha_flex">
                        <img src={trickCheck} alt="체크 아이콘" />
                        <p>다양한 코인에 적용할 수 있는 알고리즘</p>
                    </div>
                </div>
            </div>
            <div className="gridcontainer">
                <div className="flexcontainer2">
                    <div className="box1 aos-init" data-aos="zoom-in-up" data-aos-duration="1500">
                        <div className="in">
                            <p className="title-box">비트코인<br />홀딩 대비 성과</p>
                            <p className="title-sub">현물거래전용</p>
                            <div className="inbox-line">
                                <div className="inbox-flex">
                                    <p className="inbox-p">총 수익률</p>
                                    <p className="inbox-num">48.23%</p>
                                </div>
                                <div className="inbox-flex">
                                    <p className="inbox-p">월 환산 수익률</p>
                                    <p className="inbox-num">12.05%</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <p className="flexcontainer_underp2">
                위 수익률은 2022.05.01 ~ 2022.08.30까지의 결과입니다.
            </p>
            <p id="target3" className="line"></p>
        </section>
    );
};

export default Sirius;