// src/components/Dantaro.js
import React from 'react';
import dantarologo from '../../assets/images/dantarologo.png'
import dantaro1 from '../../assets/images/dantaro1.svg'
import dantaro1_m from '../../assets/images/dantaro1_m.png'
import dantaro_checl from '../../assets/images/dantaro_checl.svg'

const Dantaro = () => {
  const openLink = (url) => {
    window.open(url, '_blank');
  };

  return (
    <div className="Dantaro">
      <div className="dantaro">
        <div className="div-img">
          <img src={dantarologo} className="dantaro-service" alt="Dantaro"/>
        </div>
        <p className="dantaro-t"></p>
        <p class="sub_title_wrap"><span className="dantaro-colort">Trend Analysis-Based Financial Service</span></p>
        <div className="dantaro_box">
          <div className="dantaro-b2b">
            <p>B2B</p>
          </div>
          <div className="dantaro-b2b dantaro_go" onClick={() => openLink('https://portal.gmc-labs.com')}>
            <p>Go to Dantaro</p>
          </div>
        </div>
        <div className="grid-service">
          <div className="grid-display">
            <div className="img_wrap">
              <img src={dantaro1} className="mobi_none" alt="Dantaro"/>
              <img src={dantaro1_m} className="desk_none" alt="Dantaro Mobile"/>
            </div>
            <div className="flex-divline">
              <div className="flex-div">
                <img src={dantaro_checl} alt="Check"/>
                <p>Service partnership with Global Exchange OKX.</p>
              </div>
              <div className="flex-div">
                <img src={dantaro_checl} alt="Check"/>
                <p>There are two algorithms: buying and selling algorithms.</p>
              </div>
              <div className="flex-div">
                <img src={dantaro_checl} alt="Check"/>
                <p>Automatic algorithm conversion according to the fall and rise of the market.</p>
              </div>
              <div className="flex-div">
                <img src={dantaro_checl} alt="Check"/>
                <p>Losses through automatic algorithm switching are minimal and continuous revenue can be generated.</p>
              </div>
              <div className="flex-div">
                <img src={dantaro_checl} alt="Check"/>
                <p>Easy and simple user-oriented UI.</p>
              </div>
              <div className="flex-div">
                <img src={dantaro_checl} alt="Check"/>
                <p>Easy to use on mobile with responsive UI.</p>
              </div>
            </div>
          </div>
          <div className="grid-under">
            <div className="flex-underbox">
              <p>Chart indicator analysis</p>
            </div>
            <div className="flex-underbox">
              <p>API-Key linked</p>
            </div>
            <div className="flex-underbox">
              <p>Stable revenue</p>
            </div>
            <div className="flex-underbox">
              <p>Easy to use</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dantaro;