// src/components/ContactUs.js
import React from 'react';
import '../../styles/ContactUs.css'; // 스타일 파일을 따로 만들어서 import 하세요.
import contatctImg from '../../assets/images/contatct.svg'
import gmcKor from '../../assets/images/gmcKor_2022.pdf'

const ContactUs = () => {
  return (
    <section className="cont2 cont2_en">
      <div className="under-div">
        <p className="section_title">Contact us</p>
        <div className="under-grid">
          <div>
            <img src={contatctImg} alt="Contact" />
          </div>
          <div className="under-box">
            <p className="unbox-p">
              GMC Labs is waiting for a variety of partnership opportunities, including business<br/>
              partnerships and investment inquiries.
            </p>
            <div className="contatct-border">
              <a href="mailto:help@gmc-labs.com">help@gmc-labs.com</a>
            </div>
            <p className="unbox-p">
              More information can be downloaded.
            </p>
            <div className="contatct-border">
              <a href={gmcKor} download="회사소개서 다운로드">Introduction Download</a>
            </div>
          </div>
        </div>
        </div>
    </section>
  );
};

export default ContactUs;