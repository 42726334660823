// src/components/Buligo.js
import React from 'react';
import bulligologo from '../assets/images/bulligologo.png'; // 스타일 파일을 따로 만들어서 import 하세요.
import '../styles/Buligo.css'
import checkBuligo from '../assets/images/check-buligo.svg'
import buligoPhone from '../assets/images/buligo-phone.svg'
import buligoPhoneM from '../assets/images/buligo-phone_m.png'

const Buligo = () => {
  return (
    <div className="buligo">
      <div className="div-img">
        <img src={bulligologo} alt="불리고 서비스" className="buligo-service" />
      </div>
      <p className="buligo-t">불리고</p>
      <p><span className="buligo-colort mb_font2">레오알파 알고리즘 기반의 로보어드바이저 서비스</span></p>
      <div className="service-end">
        <p>서비스 종료</p>
      </div>
      <div className="grid-service">
        <div className="grid-display">
          <div className="img_wrap">
            <img src={buligoPhone} alt="불리고 전화 아이콘" className="mobi_none" />
            <img src={buligoPhoneM} alt="불리고 모바일 전화 아이콘" className="desk_none" />
          </div>
          <div className="flex-divline">
            <div className="flex-div">
              <img src={checkBuligo} alt="체크 아이콘" />
              <p>4개 거래소 서비스 지원 (업비트, 빗썸, 코인원, 비트멕스)</p>
            </div>
            <div className="flex-div">
            <img src={checkBuligo} alt="체크 아이콘" />
              <p>검증된 시스템 트레이딩으로 분산 투자하여 자산관리</p>
            </div>
            <div className="flex-div">
            <img src={checkBuligo} alt="체크 아이콘" />
              <p>다양한 투자 전략 구성 가능</p>
            </div>
            <div className="flex-div">
            <img src={checkBuligo} alt="체크 아이콘" />
              <p>쉽고 간단한 사용자 지향형 UI</p>
            </div>
            <div className="flex-div">
            <img src={checkBuligo} alt="체크 아이콘" />
              <p>반응형 UI로 모바일로도 간편하게 사용</p>
            </div>
          </div>
        </div>
        <div className="grid-under">
          <div className="flex-underbox">
            <p>매매 시점 분석</p>
          </div>
          <div className="flex-underbox">
            <p>API 연동</p>
          </div>
          <div className="flex-underbox">
            <p>BTC 기반 트레이딩</p>
          </div>
          <div className="flex-underbox">
            <p>로보어드바이저</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Buligo;