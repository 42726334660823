import React from 'react';

const MissionVision = () => {
  return (
    <div id="MissionVision">
      <div className="MissionVision_img">
      </div>
      <h2 className="section_title">Mission &amp; Vision</h2>
      <div className="text_wrap">
        <p className="body_text">
          GMC LABS is short for "Good Man Company," and it is a place where good people<br class="mobi_none" />
          gather to focus on innovation.<br class="mobi_none" />
          GMC LABS trading system said, <span class="last_text">"Everyone <span class="point_text">should be able
          </span> to invest digital assets<br class="mobi_none" />
          easily."</span> was with the service ideology.
        </p>
        <p className="body_text">
          The vast majority of those who invest are ordinary people. <br />
          We thought about creating an easy financial service for ordinary people.<br />
          The ideology was to recognize the finiteness of capital and to provide a reasonable<br class="mobi_none" />
          price for use so that small investments can be made, and above all, to avoid<br class="mobi_none" />
          damaging daily life.
        </p>
        <p className="body_text">
          Therefore, GMC LABS trading system uses chart analysis and algorithms of various<br />
          digital assets to trade on its own. <br />
          As a result, users can use digital assets without difficulty even if they are not<br />
          familiar with them or have never traded directly.
        </p>
        <p className="body_text">
          We believe that the trading system will help people create rational investment<br />
          habits and spend a better time in their daily life.
        </p>
        <p className="body_text">
          Furthermore, we want to promote that digital assets are sufficiently valuable as<br />
          investment assets and that they can invest rational and reliably, and to realize the<br />
          popularization of digital asset financial technology.
        </p>
      </div>
    </div>
  );
};

export default MissionVision;