import React from 'react';

const MissionVision = () => {
  return (
    <div id="MissionVision">
      <div className="MissionVision_img">
      </div>
      <h2 className="section_title">Mission &amp; Vision</h2>
      <div className="text_wrap">
        <p className="body_text">
          지엠씨랩스(GMC LABS)는 Good Man Company의 줄임말로, 좋은 사람들이 모여 혁신에 집중하고자 하는 곳입니다.<br />
          지엠씨랩스의 트레이딩 시스템은 <span className="last_text">“누구나 <span className="point_text">쉽고 간편하게</span> 디지털 자산 재테크를 할 수 있어야 한다.”</span>는<br className="mobi_none" />철학으로 탄생했습니다.
        </p>
        <p className="body_text">
          재테크를 하는 사람들의 절대 다수는 평범한 사람들입니다.<br />
          우리는 평범한 사람들을 위한 쉬운 재테크 서비스를 만들고자 고민했습니다.<br />
          자본의 유한성을 인지하고 소액 투자가 가능할 것. 이용에 합리적인 가격을 제시할 것. 무엇보다 일상을 해치지 않도록 할 것.
        </p>
        <p className="body_text">
          그래서 지엠씨랩스의 트레이딩 시스템은 다양한 코인들의 차트 분석과 알고리즘을 이용하여 알아서 거래를 합니다.<br />
          덕분에 사용자는 디지털 자산에 대해 잘 모르더라도, 직접 거래를 해본 적이 없어도 무리 없이 사용할 수 있습니다.<br />
        </p>
        <p className="body_text">
          트레이딩 시스템을 통해 사람들이 건강한 투자 습관을 만들고, 일상 속에서 더욱 나 다운 시간을 보낼 수 있을 거라고 믿습니다.
        </p>
        <p className="body_text">
          나아가 디지털 자산도 투자자산으로써 충분한 가치가 있으며 건강하고 안정적으로 투자할 수 있다는 것을 알리고,<br />
          디지털 자산 재테크의 대중화를 실현하고자 합니다.
        </p>
      </div>
    </div>
  );
};

export default MissionVision;