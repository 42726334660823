import React from 'react';

const Footer = () => {
  return (
    <footer id="footer">
      <div id="footerIn">
        <div className="finfo">
          <p><span>GMC Labs Co., Ltd.</span> Name of Representative: Daniel Kwon</p>
          <p><span>Business Registration Number</span>: 467-87-01727</p>
          <p>12F, WEWORK 133 Jeonpo-daero, Nam-gu, Busan, South Korea</p>
          <p>help@gmc-labs.com</p>
        </div>
        <hr />
        <div className="copy">
          Copyright © GMC LABS Inc. ALL RIGHTS RESERVED.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
