import React from 'react';

const AffiliateSection = () => {
  return (
    <section className="affiliate_section">
      <div className="inner">
        <div className="affiliate_img">
        </div>
        <h4 className="section_title">Affiliate</h4>
        <div className="aff_wrap">
          <div className="left_logo"></div>
          <div className="right_text">
            <h4 className="aff_title">디파이언스</h4>
            <p className="aff_subtitle">당신의 삶에 더 나은 가치를 제안합니다.</p>
            <p className="aff_text">
              지엠씨랩스의 트레이딩 시스템을 기반으로 재테크 서비스를 제공합니다.<br />
              디지털 자산 재테크 서비스의 글로벌 리딩 기업으로 자리매김하고,<br />
              투자자산으로 충분한 가치가 있으며 건강하고 안정적으로 투자할 수 있도록<br />
              재테크의 대중화를 실현하겠습니다.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AffiliateSection;