import React, { useState, useEffect } from "react";
import logo from "../assets/images/logo1.png";
import { useNavigate, NavLink, useLocation } from "react-router-dom"; // useHistory 대신 useNavigate로 변경, NavLink 추가, useLocation 추가

const Header = ({ openMenu, setOpenMenu }) => {
  const navigate = useNavigate(); // useHistory를 useNavigate로 변경
  const location = useLocation(); // 현재 경로 가져오기
  const [language, setLanguage] = useState("ko");

  useEffect(() => {
    // URL에서 현재 언어 확인
    const currentLang = location.pathname.includes("_en") ? "en" : "ko";
    setLanguage(currentLang);
  }, [location]);

  const menuItems = {
    ko: ["회사소개", "사업영역", "소식"],
    en: ["About Us", "Services", "News"],
  };

  const paths = {
    ko: ["/", "/Service", "/News"],
    en: ["/Home_en", "/Service_en", "/News_en"],
  };

  const changeLanguage = (lang) => {
    const currentPath = location.pathname;
    let newPath;

    if (lang === "en") {
      if (currentPath === '/') {
        newPath = 'Home_en';
      } else {
        newPath = currentPath.includes("_en") ? currentPath : currentPath + "_en";
      }
    } else {
      if (currentPath === '/Home_en') {
        newPath = '/';
      } else {
        newPath = currentPath.replace("_en", "") === "/Home" ? "/" : currentPath.replace("_en", "");
      }
    }
    navigate(newPath);
  };

  return (
    <div className="header">
      <div className="header_wrap">
        <img
          className="logo"
          src={logo}
          style={{ cursor: "pointer" }}
          alt="로고" // alt 속성 추가
        />
        <ul className="gnb">
          {menuItems[language].map((item, index) => (
            <li key={index}>
              <NavLink
                to={paths[language][index]}
                className={
                  location.pathname === paths[language][index] ? "on" : ""
                }
                onClick={() => setOpenMenu(false)}
              >
                {item}
              </NavLink>
            </li>
          ))}
        </ul>
        <div className="header_right">
          <div className="lang_wrap">
            <div className="lang_img">
              {language === "ko" ? "언어변경" : "Change Language"}
            </div>
            <ul className="lang_list">
              <li className={`lang_btn ${language === "ko" ? "on" : ""}`} onClick={() => changeLanguage("ko")}>KOR
              </li>
              <li className={`lang_btn ${language === "en" ? "on" : ""}`} onClick={() => changeLanguage("en")}>ENG
              </li>
            </ul>
          </div>
        </div>
        <button
            className={`menu_btn ${openMenu ? "on" : ""}`}
            onClick={() => setOpenMenu(!openMenu)}
        />
      </div>
      <nav className={`mo_gnb ${openMenu ? "on" : ""}`}>
        <ul>
          {menuItems[language].map((item, index) => (
            <li key={index}>
              <NavLink
                to={paths[language][index]}
                className={
                  location.pathname === paths[language][index] ? "on" : ""
                }
                onClick={() => setOpenMenu(false)}
              >
                {item}
              </NavLink>
            </li>
          ))}
          <div className="lang_wrap">
            <li className={`lang_btn ${language === "ko" ? "on" : ""}`} onClick={() => changeLanguage("ko")}>KOR
            </li>
            <li className={`lang_btn ${language === "en" ? "on" : ""}`} onClick={() => changeLanguage("en")}>ENG
            </li>
          </div>
        </ul>
      </nav>
      <div
          className={`sidebar-overlay ${openMenu ? "on" : ""}`}
          onClick={() => setOpenMenu(false)}
      ></div>
    </div>
  );
};

export default Header;