// src/components/ContactUs.js
import React from 'react';
import '../styles/ContactUs.css'; // 스타일 파일을 따로 만들어서 import 하세요.
import contatctImg from '../assets/images/contatct.svg'
import gmcKor from '../assets/images/gmcKor_2022.pdf'
const ContactUs = () => {
  return (
    <section className="cont2">
      <div className="under-div">
        <p className="section_title">Contact us</p>
        <div className="under-grid">
          <div>
            <img src={contatctImg} alt="Contact" />
          </div>
          <div className="under-box">
            <p className="unbox-p">
              지엠씨랩스는 사업 제휴, 투자 문의 등 다양한 제휴 기회를 기다리고 있습니다.
            </p>
            <div className="contatct-border">
              <a href="mailto:help@gmc-labs.com">help@gmc-labs.com</a>
            </div>
            <p className="unbox-p">
              지엠씨랩스에 대한 자세한 설명을 다운로드 받으실 수 있습니다.
            </p>
            <div className="contatct-border">
              <a href={gmcKor} download="회사소개서 다운로드">회사소개서 다운로드</a>
            </div>
          </div>
        </div>
        </div>
    </section>
  );
};

export default ContactUs;