// src/components/Buligo.js
import React from 'react';
import '../../styles/Buligo.css'
import bulligologo from '../../assets/images/bulligologo.png'; // 스타일 파일을 따로 만들어서 import 하세요.
import checkBuligo from '../../assets/images/check-buligo.svg'
import buligoPhone from '../../assets/images/buligo-phone.svg'
import buligoPhoneM from '../../assets/images/buligo-phone_m.png'

const Buligo = () => {
  return (
    <div className="buligo">
      <div className="div-img">
        <img src={bulligologo} alt="불리고 서비스" className="buligo-service" />
      </div>
      <p className="buligo-t"></p>
      <p class="sub_title_wrap"><span className="buligo-colort mb_font2">Leo Alpha Algorithm-basedRoboAdvisor Services</span></p>
      <div className="service-end">
        <p class="mobi_font">Terminated of service</p>
      </div>
      <div className="grid-service">
        <div className="grid-display">
          <div className="img_wrap">
            <img src={buligoPhone} alt="불리고 전화 아이콘" className="mobi_none" />
            <img src={buligoPhoneM} alt="불리고 모바일 전화 아이콘" className="desk_none" />
          </div>
          <div className="flex-divline">
            <div className="flex-div">
              <img src={checkBuligo} alt="체크 아이콘" />
              <p>4 Exchange Service Support</p>
            </div>
            <div className="flex-div">
            <img src={checkBuligo} alt="체크 아이콘" />
              <p>Distributed investment with proven system trading to manage assets.</p>
            </div>
            <div className="flex-div">
            <img src={checkBuligo} alt="체크 아이콘" />
              <p>A variety of investment strategies can be constructed.</p>
            </div>
            <div className="flex-div">
            <img src={checkBuligo} alt="체크 아이콘" />
              <p>Easy and simple user-oriented UI.</p>
            </div>
            <div className="flex-div">
            <img src={checkBuligo} alt="체크 아이콘" />
              <p>Easy to use on mobile with responsive UI.</p>
            </div>
          </div>
        </div>
        <div className="grid-under">
          <div className="flex-underbox">
            <p>Sales timing analysis</p>
          </div>
          <div className="flex-underbox">
            <p>API-key linked</p>
          </div>
          <div className="flex-underbox">
            <p>BTC-based trading</p>
          </div>
          <div className="flex-underbox">
            <p>Robo advisor</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Buligo;