import React from 'react';

const historyData = [
  {
    year: 2023,
    events: [
      { month: '09', text: 'EQ Hub 기반 메인넷 구축' },
      { month: '08', text: '2023 코리아 핀테크위크 참가' },
      { month: '06', text: '단타로 프로(Dantaro Pro) B2B 서비스 오픈' },
      { month: '03', text: 'MSA 시스템 전환' },
      { month: '01', text: '단타로 OKX Perp 베타서비스 오픈' },
    ],
  },
  {
    year: 2022,
    events: [
      { month: '12', text: '2022 부산머니쇼 참가' },
      { month: '11', text: 'SCUTUM B2B 서비스 오픈' },
      { month: '06', text: '부산상공회의소 S-Space 입주' },
      { month: '05', text: '단타로(Dantaro) B2B 서비스 오픈' },
      { month: '04', text: 'S-Space 협약 체결' },
    ],
  },
  {
    year: 2021,
    events: [
      { month: '12', text: '디지털 금융서비스 글로벌 비즈니스 론칭' },
      { month: '11', text: 'OKX 거래소 × 재테크 서비스 ‘단타로(Dantaro)’ 제휴' },
      { month: '08', text: '싱가포르 현지 법인 디파이언스(DFians) 설립' },
      { month: '05', text: '2021 서울머니쇼 참가' },
      { month: '03', text: '레오알파(LeoAlpha) 알고리즘 특허 출원' },
    ],
  },
  {
    year: 2020,
    events: [
      { month: '12', text: '불리고(bulligo) 정식 서비스 오픈' },
      { month: '10', text: '부산시 - 하나카드 핀테크 챌린지 최우수상 수상' },
      { month: '09', text: '기업 부설 연구소 설립' },
      { month: '08', text: '벤처기업 인증. B-Investment Show에서 2등 수상.' },
      { month: '07', text: '오픈트레이드 크라우드펀딩 투자유치' },
      { month: '03', text: '주식회사 지엠씨랩스 법인전환' },
      { month: '01', text: 'BNK 핀테크랩 1기 선발' },
    ],
  },
  {
    year: 2019,
    events: [
      { month: '11', text: '2019 부산 머니쇼 참가' },
      { month: '09', text: '부산시와 MOU체결' },
      { month: '08', text: '지엠씨랩스 (GMC LABS) 설립' },
    ],
  },
];

const History = () => {
  return (
    <div id="history">
      <div className="history_img"></div>
      <h2 className="section_title">History</h2>
      <div className="history_wrap">
        {historyData.map((item) => (
          <div className="grid_box" key={item.year}>
            <p className="year">{item.year}</p>
            <div className="history_contents">
              {item.events.map((event, index) => (
                <div className="month_wrap" key={index}>
                  <p>{event.month}</p>
                  <div className="history_text">
                    <p>{event.text}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default History;