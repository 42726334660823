import React from 'react'
import '../styles/Members.css'
import daniel from '../assets/images/daniel.png'
import steve from '../assets/images/steve.png'
import yang from '../assets/images/yang.png'

const MemberCard = ({ name, photo, description }) => {
    return (
        <div className="member-card">
            <img src={photo} alt={name} />
            <h3>{name}</h3>
            <div>
            {description.map((desc, index) => (
                <p key={index}>{desc}</p> // 각 항목을 <p>로 출력
            ))}
            <br/>
            </div>
        </div>
    );
}

function Members() {
    return (
        <div id="members">
            <div className="members_img">
            </div>
            <h4 className="section_title">Members</h4>
            <div className="members_wrap">
                <MemberCard name="권세영" photo={daniel} description={["디지털자산 재테크 프로젝트 기획", "트레이딩 시스템 기획 및 알고리즘 개발", "블로첵인기반 서비스 구축 컨설팅"]}/>
                <MemberCard name="유연호" photo={steve} description={["알고리즘 전략과 분석 시스템 개발", "자동매매 트레이딩 알고리즘 개발"]} />
                <MemberCard name="양경석" photo={yang} description={["단타로 서비스 운영 총괄본부장", "재테크 트레이딩 시스템 운영 및 교육"]} />
            </div>
        </div>
    )
}

export default Members
