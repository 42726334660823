// src/components/Mougo.js
import React from 'react';
import '../styles/mougo.css'
import mougo1 from '../assets/images/mougo1.svg'
import mougo1_m from '../assets/images/mougo1_m.png'
import mougo_check from '../assets/images/mougo_check.svg'
import mougologo from '../assets/images/mougologo.png'

const Mougo = () => {
  return (
    <div className="mougo">
      <div className="mougo-div">
        <div className="div-img">
          <img src={mougologo} className="bonego-service" alt="로고" />
        </div>
        <p className="mougo-t">모으고</p>
        <p><span className="mougo-colort">통합 자산관리 플랫폼</span></p>
        <div className="mougo-b2b">
          <p>출시 예정</p>
        </div>
        <div className="grid-service">
          <div className="grid-display">
            <div className="img_wrap">
              <img src={mougo1} className="mobi_none" alt="Mougo" />
              <img src={mougo1_m} className="desk_none" alt="Mougo Mobile" />
            </div>
            <div className="flex-divline">
              <div className="flex-div">
                <img src={mougo_check} alt="Check" />
                <p>단 하나의 앱으로 재테크와 자산관리를 한 곳에서</p>
              </div>
              <div className="flex-div">
                <img src={mougo_check} alt="Check" />
                <p>계좌연동부터 트레이딩까지 자산관리의 모든 것</p>
              </div>
              <div className="flex-div">
                <img src={mougo_check} alt="Check" />
                <p>기존 핀테크 투자 상품 연계</p>
              </div>
              <div className="flex-div">
                <img src={mougo_check} alt="Check" />
                <p>재테크 서비스로 내 자산은 불리고</p>
              </div>
              <div className="flex-div">
                <img src={mougo_check} alt="Check" />
                <p>자산관리에 필요한 기능은 모으고</p>
              </div>
            </div>
          </div>
          <div className="grid-under">
            <div className="flex-underbox">
              <p>거래소 통합관리</p>
            </div>
            <div className="flex-underbox">
              <p>재테크 서비스</p>
            </div>
            <div className="flex-underbox">
              <p>매매 타이밍 알림</p>
            </div>
            <div className="flex-underbox">
              <p>오픈뱅킹</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mougo;