// src/components/Mougo.js
import React from 'react';
import '../../styles/mougo.css'
import mougo1 from '../../assets/images/mougo1.svg'
import mougo1_m from '../../assets/images/mougo1_m.png'
import mougo_check from '../../assets/images/mougo_check.svg'
import mougologo from '../../assets/images/mougologo.png'

const Mougo = () => {
  return (
    <div className="mougo">
      <div className="mougo-div">
        <div className="div-img">
          <img src={mougologo} className="bonego-service" alt="로고" />
        </div>
        <p className="mougo-t"></p>
        <p class="sub_title_wrap"><span className="mougo-colort">Integrated Asset Management Platform</span></p>
        <div className="mougo-b2b">
          <p>Coming soon</p>
        </div>
        <div className="grid-service">
          <div className="grid-display">
            <div className="img_wrap">
              <img src={mougo1} className="mobi_none" alt="Mougo" />
              <img src={mougo1_m} className="desk_none" alt="Mougo Mobile" />
            </div>
            <div className="flex-divline">
              <div className="flex-div">
                <img src={mougo_check} alt="Check" />
                <p>With just one application, you can manage your finances and assets in one place</p>
              </div>
              <div className="flex-div">
                <img src={mougo_check} alt="Check" />
                <p>Everything about asset management from account linked to trading</p>
              </div>
              <div className="flex-div">
                <img src={mougo_check} alt="Check" />
                <p>Link existing fintech investment products</p>
              </div>
              <div className="flex-div">
                <img src={mougo_check} alt="Check" />
                <p>Financial services increase my assets</p>
              </div>
              <div className="flex-div">
                <img src={mougo_check} alt="Check" />
                <p>Consolidate the capabilities required for asset management</p>
              </div>
            </div>
          </div>
          <div className="grid-under">
            <div className="flex-underbox">
              <p>Exchange integrated management</p>
            </div>
            <div className="flex-underbox">
              <p>Financial services</p>
            </div>
            <div className="flex-underbox">
              <p>Timing notification</p>
            </div>
            <div className="flex-underbox">
              <p>Open banking</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mougo;