import React from 'react';

const historyData = [
  {
    year: 2023,
    events: [
      { month: '09', text: 'EQ Hub-based mainnet construction' },
      { month: '08', text: 'Participated in 2023 Korea Fintech Week' },
      { month: '06', text: 'Dantaro Pro B2B Service open' },
      { month: '03', text: 'MSA system conversion' },
      { month: '01', text: 'Launched Beta service \'Dantaro X OKX Perp\'' },
    ],
  },
  {
    year: 2022,
    events: [
      { month: '12', text: 'Attended 2022 Busan Money Show in BEXCO' },
      { month: '11', text: 'Launched B2B service \'SCUTUM\'' },
      { month: '06', text: 'Moved in Busan Chamber of Commerce & Industry(BCCI) U-Space\n' +
              '\n' +
              'Seoul Startup Hub-DB Open Innovation Selection\n' +
              '\n' +
              '(Establishment of Seoul Branch)' },
      { month: '05', text: 'Launched ‘Dantaro’ of B2B digital financial services' },
      { month: '04', text: 'S-Space Agreement\n' +
              '(Creating and fostering business environment for Fintech small and \n medium-sized enterprises)' +
              '\n' +
              'Selection of 11th phase of the Korea Credit Guarantee Fund Start-UP NEST' },
    ],
  },
  {
    year: 2021,
    events: [
      { month: '12', text: 'Launched a global business of digital financial services' },
      { month: '11', text: 'uto Trading System \'Dantaro\' Service Alliance(OKX Exchange)\n' +
            '\n' +
            'Attended 2021 Busan Money Show' },
      { month: '08', text: 'Establishment of \'DFians\' of Singapore\'s branch' },
      { month: '05', text: 'Attended 2021 Seoul Money Show' },
      { month: '03', text: 'Patent application ‘Leo Alpha algorithm’' },
    ],
  },
  {
    year: 2020,
    events: [
      { month: '12', text: 'Launched digital assets AI robo-advisor ‘BULLIGO’ official services.' },
      { month: '10', text: 'Gained the Grand Prize in “Fintech Challenge ”\n' +
            'co-hosted by Busan Government & Hana Card.' },
      { month: '09', text: 'Set up GMC LABS affiliated Research Institute' },
      { month: '08', text: 'Certified as a venture company.\n' +
            'Gained the 2nd prize in B-Investment Show.' },
      { month: '07', text: 'Attracted investment from “Open Trade crowd funding”' },
      { month: '03', text: 'Converted to GMC LABS Inc.' },
      { month: '01', text: 'Selected in the 1st session of BNK Fintech Lab.' },
    ],
  },
  {
    year: 2019,
    events: [
      { month: '11', text: 'Attended 2019 Busan Money Show\n' +
            'Attended ASEAN-Republic of Korea\n' +
            'Commemorative Summit - Blockchain conference.' },
      { month: '09', text: 'Signed MOU with Busan city.\n' +
            '(A business agreement for the development of financial \n technology such as Fintech/Blockchain, etc.)' },
      { month: '08', text: 'Established GMC LABS – Individual business.' },
    ],
  },
];

const HistoryEn = () => {
  return (
    <div id="history">
      <div className="history_img"></div>
      <h2 className="section_title">History</h2>
      <div className="history_wrap">
        {historyData.map((item) => (
          <div className="grid_box" key={item.year}>
            <p className="year">{item.year}</p>
            <div className="history_contents">
              {item.events.map((event, index) => (
                <div className="month_wrap" key={index}>
                  <p>{event.month}</p>
                  <div className="history_text">
                      {event.text.split('\n').map((line, lineIndex) => (
                      <p key={lineIndex}>{line}</p>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HistoryEn;