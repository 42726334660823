// src/components/Bonego.js
import React from 'react';
import '../styles/Bonego.css'; // 스타일 파일을 따로 만들어서 import 하세요.
import bonegologo from '../assets/images/bonegologo.png'
import bonego1 from '../assets/images/bonego1.svg'
import bonego1M from '../assets/images/bonego1_m.png'
import bonegoCheck from '../assets/images/bonego_check.svg'

const Bonego = () => {
  return (
    <div className="bonego">
      <div className="bonego-div">
        <div className="div-img">
          <img src={bonegologo} alt="보내고 서비스" className="bonego-service" />
        </div>
        <p className="bonego-t">보내고</p>
        <p><span className="bonego-colort">블록체인 기반 멀티채널 거래 시스템</span></p>
        <div className="bonego-b2b">
          <p>업무제휴</p>
        </div>
        <div className="grid-service">
          <div className="grid-display">
            <div className="img_wrap">
              <img src={bonego1} alt="보내고 서비스 이미지" className="mobi_none" />
              <img src={bonego1M} alt="보내고 모바일 서비스 이미지" className="desk_none" />
            </div>
            <div className="flex-divline">
              <div className="flex-div">
                <img src={bonegoCheck} alt="체크 아이콘" />
                <p>국제 간 환전부터 송금, 결제까지 All-in-one 지갑</p>
              </div>
              <div className="flex-div">
              <img src={bonegoCheck} alt="체크 아이콘" />
                <p>해외에서 원화로 바로 결제 : 자동 환전</p>
              </div>
              <div className="flex-div">
              <img src={bonegoCheck} alt="체크 아이콘" />
                <p>환전한 돈으로 간편 결제</p>
              </div>
              <div className="flex-div">
              <img src={bonegoCheck} alt="체크 아이콘" />
                <p>환전 즉시 바로 송금</p>
              </div>
              <div className="flex-div">
              <img src={bonegoCheck} alt="체크 아이콘" />
                <p>부산시-하나카드 핀테크 챌린지 최우수상 수상 아이템</p>
              </div>
            </div>
          </div>
          <div className="grid-under">
            <div className="flex-underbox">
              <p>간편 환전</p>
            </div>
            <div className="flex-underbox">
              <p>간편 해외송금</p>
            </div>
            <div className="flex-underbox">
              <p>간편 해외결제</p>
            </div>
            <div className="flex-underbox">
              <p>블록체인 기반 시스템</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bonego;