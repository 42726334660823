import React, { useEffect, useState } from 'react';
import AOS from 'aos'; // AOS 임포트
import 'aos/dist/aos.css'; // AOS CSS 임포트
import '../styles/service.css'
import '../styles/header.css'
import '../styles/common.css'
import '../styles/reset.css'
import '../styles/topbtn.css'
import Header from '../components/Header';
import Dantaro from '../components/Dantaro';
import Mougo from '../components/mougo';
import Dfians from '../components/Dfians';
import Footer from '../components/Footer';
import ContactUs from '../components/ContactUs';
import ChartSection from '../components/ChartSection';
import Buligo from '../components/Buligo';
import Bonego from '../components/Bonego';
import Sirius from '../components/sirius';

const Service = () => {
    const [openMenu, setOpenMenu] = useState(false);
    const [scrollActive, setScrollActive] = useState(false);

    useEffect(() => {
        AOS.init();
        const handleScroll = () => {
          setScrollActive(window.scrollY > 800);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
      }, []);

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const down_sr = () => {
        scrollToSection('serviceSection');
    };

    const down_tr = () => {
        scrollToSection('chartSection');
    };

    const down_al = () => {
        scrollToSection('siriusSection');
    };

    const toTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };
    

    return (
        <>
            <Header openMenu={openMenu} setOpenMenu={setOpenMenu} />
            <div id="servicemainVisual">
                <div className="servicemainSlider">
                    <div className="msImg"></div>
                    <div className="tbox">
                        <h4>사업영역</h4>
                    </div>
                </div>
            </div>
            <div id="container">
                <section className="moeugo" id="serviceSection">
                    <div className="btndiv">
                        <button className="btn1" onClick={down_sr}>서비스</button>
                        <button className="btn2" onClick={down_tr}>트레이딩 시스템</button>
                        <button className="btn3" onClick={down_al}>알고리즘</button>
                    </div>
                    <div className="section_title_box">
                        <p className="section_title">
                            Service
                        </p>
                    </div>
                    <Dantaro />
                    <Mougo />
                    <Dfians />
                    <Bonego />
                    <Buligo />
                </section>
                <ChartSection id="chartSection" />
                <Sirius id="siriusSection" />
            </div>
            <ContactUs />
            <Footer />
            <button
                className={`btn_top_display ${scrollActive ? 'on' : ''}`}
                onClick={toTop}
            />
        </>
    );
};

export default Service;