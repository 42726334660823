// src/components/ChartSection.js
import React from 'react';
import '../styles/ChartSection.css'; // 스타일 파일을 따로 만들어서 import 하세요.
import trick_check from '../assets/images/trick_check.svg'

const ChartSection = ({ id }) => {
    return (
        <section className="chart" id={id}>
            <div className="section_title_box">
                <p className="section_title">Trading system</p>
            </div>
            <div className="display_grid">
                <div>
                    <p className="leoalpha_font">자산 운용 시스템</p>
                    <p className="big-p">자체 개발한 알고리즘을 결합한 자산운용 시스템</p>
                    <div className="trading-line">
                        <div className="trading_flex">
                            <img src={trick_check} alt="체크 아이콘" />
                            <p className="line-m">개인용 자산운용 시스템과 기관용 자산운용 시스템 구성</p>
                        </div>
                        <div className="trading_flex">
                            <img src={trick_check} alt="체크 아이콘" />
                            <p className="line-m">다중 거래소 지원 가능</p>
                        </div>
                        <div className="trading_flex">
                            <img src={trick_check} alt="체크 아이콘" />
                            <p className="line-m">다양한 코인 운용으로 포트폴리오 구성 가능</p>
                        </div>
                        <div className="trading_flex">
                            <img src={trick_check} alt="체크 아이콘" />
                            <p>쉽고 간단한 사용자 지향형 UI</p>
                        </div>
                        <div className="trading_flex">
                            <img src={trick_check} alt="체크 아이콘" />
                            <p className="line-m">반응형 UI로 모바일로도 간편하게 사용</p>
                        </div>
                    </div>
                </div>
                <div>
                    <p className="leoalpha_font">유동성 운용 시스템</p>
                    <p className="big-p">재단과 거래소를 위한 유동성 제공</p>
                    <div className="trading-line">
                        <div className="trading_flex">
                            <img src={trick_check} alt="체크 아이콘" />
                            <p>세분화된 설정으로 쉽고 간단하게 사용</p>
                        </div>
                        <div className="trading_flex">
                            <img src={trick_check} alt="체크 아이콘" />
                            <p className="line-m">한 번의 클릭으로 거래소 간의 간편한 재정거래</p>
                        </div>
                        <div className="trading_flex">
                            <img src={trick_check} alt="체크 아이콘" />
                            <p className="line-m">봇 공격에 대응하는 알고리즘 적용</p>
                        </div>
                        <div className="trading_flex">
                            <img src={trick_check} alt="체크 아이콘" />
                            <p className="line-m">거래소 친화적인 인터페이스로 사용자 지향형 UI</p>
                        </div>
                        <div className="trading_flex">
                            <img src={trick_check} alt="체크 아이콘" />
                            <p className="line-m">반응형 UI로 모바일로도 간편하게 사용</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ChartSection;