import React from 'react';

const AboutUs = ({ moreBnt }) => {
  return (
    <div id="aboutUs">
      <div className="text_wrap">
        <h2 className="section_title">About us</h2>
        <p className="body_text">
          지엠씨랩스는 디지털 자산에 특화된 트레이딩 시스템 전문 개발기업으로써,<br className="mobi_none" />
          전문적인 자산운용 기술과 자체개발한 시스템을 통해 디지털 자산 시장에서 변화와 혁신을 창출합니다.<br className="mobi_none" />
          끊임 없는 로직 연구와 기술 개발을 통해 업계 최고 수준의 알고리즘 역량으로 차별화된 경쟁력을 보유하고 있습니다.<br className="mobi_none" />
        </p>
        <p className="body_text last_text">
          지엠씨랩스는 트레이딩, <span className="point_text">그 이상의 가치</span>를 제공합니다.
        </p>
      </div>
      <div className="img_wrap">
        <div className="left_img"><p>알고리즘</p></div>
        <div className="right_img"><p>트레이딩 시스템</p></div>
      </div>
      <button type="button" className="more_bnt" onClick={moreBnt}>
        자세히 보기 →
      </button>
    </div>
  );
};

export default AboutUs;