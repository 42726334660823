// src/components/MainSection.js
import React from 'react';
import '../styles/MainSection.css'; // 스타일 파일을 따로 만들어서 import 하세요.
import vstro from '../assets/images/v-stro.jpeg'
import sspace from '../assets/images/s-space.jpg'
import Dfiansimg from '../assets/images/Dfiansimg.png'
import mou from '../assets/images/mou.jpg'
import interview from '../assets/images/interview.jpg'
import seoulMoneyShow from '../assets/images/seoul_moneyShow.png'
import bulligo_open from '../assets/images/bulligo_open.png'
import interviewpng from '../assets/images/interview.png'
import arrow from '../assets/images/arrow.png'


const MainSection = () => {
    const stories = [
        { link: "https://m.ekn.kr/view.php?key=20230412001456053", imgSrc: vstro, title: " GMC Labs signs contract with V-Stro", date: "2023.04.12" },
        { link: "https://m.kmib.co.kr/view.asp?arcid=0017015980", imgSrc: sspace, title: " GMC Labs, Busan City and S-Space MOU ", date: "2022.04.26" },
        { link: "https://www.siminilbo.co.kr/news/newsview.php?ncode=1160270583494286", imgSrc: Dfiansimg, title: "GMC Lab Presents Dfians Global Vision", date: "2021.12.30" },
        { link: "https://gmclabs.tistory.com/126", imgSrc: mou, title: "GMC Labs signs MOU for technology collaboration with hiblocks", date: "2021.08.06" },
        { link: "https://gmclabs.tistory.com/125?category=924532", imgSrc: interview, title: "Busan fintech industry is led by one of them. GMC labs", date: "2021.07.08" },
        { link: "https://gmclabs.tistory.com/123?category=924532", imgSrc: seoulMoneyShow, title: "Participation 2021 Seoul Money Show.", date: "2021.05.12" },
        { link: "https://gmclabs.tistory.com/38?category=930837", imgSrc: bulligo_open, title: "Open of Bulligo service.", date: "2020.11.09" },
        { link: "https://gmclabs.tistory.com/39?category=924532", imgSrc: interviewpng, title: "An interview with Kwon Seyoung - the CEO of GMC Labs.", date: "2020.11.09" },
    ];
    return (
        <div className="main_section">
            <div className="storyFlexBox">
                {stories.map((story, index) => (
                    <StoryItem key={index} {...story} />
                ))}
            </div>
            <ul className="arrow">
                <div className="blind_view" onClick={() => {/* 더보기 기능 추가 */ }}>
                    <li>
                        <img className="more_btn" src={arrow} alt="더보기" />
                    </li>
                    <li>
                        <p className="arrow_title">Learn<br/>More</p>
                    </li>
                </div>
            </ul>
        </div>
    );
};

const StoryItem = ({ link, imgSrc, title, date }) => (
    <ul className="story plus">
        <a href={link} target="_blank" rel="noopener noreferrer">
            <li>
                <img src={imgSrc} alt={title} />
            </li>
            <li>
                <p className="story_title">{title}</p>
                <p className="day">{date}</p>
            </li>
        </a>
    </ul>
);

export default MainSection;