import React, { useEffect, useState } from 'react';
import AOS from 'aos'; // AOS 임포트
import 'aos/dist/aos.css'; // AOS CSS 임포트
import '../styles/news.css'
import '../styles/header.css'
import '../styles/common.css'
import '../styles/reset.css'
import '../styles/topbtn.css'
import Header from '../components/Header';
import Footer from '../components/Footer';
import MainSection from '../components/MainSection';

const News = () => {
    const [openMenu, setOpenMenu] = useState(false);
    const [scrollActive, setScrollActive] = useState(false);

    useEffect(() => {
        AOS.init();
        const handleScroll = () => {
          setScrollActive(window.scrollY > 800);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
      }, []);

    const toTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };
    

    

    return (
        <>
            <Header openMenu={openMenu} setOpenMenu={setOpenMenu} />
            <div id="newsmainVisual">
                <div className="newsmainSlider">
                    <div className="msImg"></div>
                    <div className="tbox">
                        <h4>소식</h4>
                    </div>
                </div>
            </div>
            <div id="newcontainer">
                <MainSection />
            </div>
            <Footer />
            <button
                className={`btn_top_display ${scrollActive ? 'on' : ''}`}
                onClick={toTop}
            />
        </>
    );
};

export default News;