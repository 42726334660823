// src/components/Dfians.js
import React from 'react';
import '../../styles/dfians.css'; // 스타일 파일을 따로 만들어서 import 하세요.
import dfianslogo from '../../assets/images/dfianslogo.png'
import Dfiansimg from '../../assets/images/Dfiansimg.svg'
import Dfiansimg_m from '../../assets/images/Dfiansimg_m.png'
import difians_check from '../../assets/images/difians_check.svg'

const Dfians = () => {
    return (
        <div className="Dfians">
            <div className="Dfians-div">
                <div className="div-img">
                    <img src={dfianslogo} alt="Dfians 서비스" className="difians-service" />
                </div>
                <p className="difians-t"></p>
                <p class="sub_title_wrap"><span className="difians-colort mb_font">Profitable wallet service with an asset<br
                    className="desk_none"/>management system</span></p>
                <div className="difians-b2b">
                    <p>B2B</p>
                </div>
                <div className="grid-service">
                    <div className="grid-display">
                        <div className="img_wrap">
                            <img src={Dfiansimg} alt="Dfians 이미지" className="mobi_none" />
                            <img src={Dfiansimg_m} alt="Dfians 모바일 이미지" className="desk_none" />
                        </div>
                        <div className="flex-divline">
                            <div className="flex-div">
                                <img src={difians_check} alt="체크 아이콘" />
                                <p>A profitable wallet that combines digital asset deposit services</p>
                            </div>
                            <div className="flex-div">
                                <img src={difians_check} alt="체크 아이콘" />
                                <p>Provide regular interest income through its own asset management system</p>
                            </div>
                            <div className="flex-div">
                                <img src={difians_check} alt="체크 아이콘" />
                                <p>Secure asset storage and ease of management</p>
                            </div>
                        </div>
                    </div>
                    <div className="grid-under">
                        <div className="flex-underbox">
                            <p>Asset management system linked</p>
                        </div>
                        <div className="flex-underbox">
                            <p>Deposit service</p>
                        </div>
                        <div className="flex-underbox">
                            <p>All time subscription</p>
                        </div>
                        <div className="flex-underbox">
                            <p>Stable interest income</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dfians;