import React from 'react';

const AboutUsEn = ({ moreBnt }) => {
  return (
    <div id="aboutUs">
      <div className="text_wrap">
        <h2 className="section_title">About us</h2>
        <p className="body_text">
          As a trading system developer specializing in digital assets, GMC LABS creates changes and<br className="mobi_none" />
          innovations in the digital asset market through professional asset management.<br className="mobi_none" />
          technologies with self developed systems.<br className="mobi_none" />
          It has a competitive edge with industry leading algorithms through continuous logi<br className="mobi_none" />
          research and technology development.
        </p>
        <p className="body_text last_text">
          GMC LABS will offer you <span className="point_text">more value than</span>trading.
        </p>
      </div>
      <div className="img_wrap">
        <div className="left_img"><p>Algorithms</p></div>
        <div className="right_img"><p>Trading system</p></div>
      </div>
      <button type="button" className="more_bnt" onClick={moreBnt}>
        LEARN MORE →
      </button>
    </div>
  );
};

export default AboutUsEn;