// src/components/Sirius.js
import React from 'react';
import '../../styles/Sirius.css'; // 스타일 파일을 따로 만들어서 import 하세요.
import trickCheck from '../../assets/images/trick_check.svg'

const Sirius = ({ id }) => {
    return (
        <section className="sirius" id={id}>
            <div className="section_title_box">
                <p className="section_title">Algorithm</p>
            </div>
            <div className="gray_box">
                <div className="leoalpha_div">
                    <p className="leoalpha_font">Leo Alpha Algorithm</p>
                    <p className="leoalpha_boxfont">completion of patent application</p>
                </div>
                <p className="big-p">
                    Algorithm by big data analysis and quant strategy based chart index analysis.
                </p>
                <div className="leoalpha_div2">
                    <div className="leoalpha_flex">
                        <img src={trickCheck} alt="체크 아이콘" />
                        <p className="line-m">Determining the timing of the sale by dividing the rise and fall.</p>
                    </div>
                    <div className="leoalpha_flex">
                        <img src={trickCheck} alt="체크 아이콘" />
                        <p className="line-m">Categorized by algorithms available in a spot market and a margin market.</p>
                    </div>
                    <div className="leoalpha_flex">
                        <img src={trickCheck} alt="체크 아이콘" />
                        <p className="line-m">Applicable to all financial markets formed by charts.</p>
                    </div>
                </div>
            </div>
            <div className="flexcontainer">
                <div className="box1 aos-init" data-aos="zoom-in-up" data-aos-duration="1500">
                    <div className="in">
                        <p className="title-box">Leo Alpha K</p>
                        <p className="title-sub">Spot transaction only</p>
                        <div className="inbox-line">
                            <div className="inbox-flex">
                                <p className="inbox-p">Cumulated rate of<br/>return</p>
                                <p className="inbox-num">453.58%</p>
                            </div>
                            <div className="inbox-flex">
                                <p className="inbox-p">Annualized rate of<br/>return</p>
                                <p className="inbox-num">113.40%</p>
                            </div>
                            <div className="inbox-flex">
                                <p className="inbox-p">MDD</p>
                                <p className="inbox-num">8.44%</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="box2 aos-init" data-aos="zoom-in-up" data-aos-duration="1500">
                    <div className="in">
                        <p className="title-box">Leo Alpha BM1</p>
                        <p className="title-sub">futures trading</p>
                        <div className="inbox-line">
                            <div className="inbox-flex">
                                <p className="inbox-p">Cumulated rate of<br/>return</p>
                                <p className="inbox-num">308.62%</p>
                            </div>
                            <div className="inbox-flex">
                                <p className="inbox-p">Annualized rate of<br/>return</p>
                                <p className="inbox-num">77.16%</p>
                            </div>
                            <div className="inbox-flex">
                                <p className="inbox-p">MDD</p>
                                <p className="inbox-num">16.22%</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="box3 aos-init" data-aos="zoom-in-up" data-aos-duration="1500">
                    <div className="in">
                        <p className="title-box">Leo Alpha BM2</p>
                        <p className="title-sub">futures trading</p>
                        <div className="inbox-line">
                            <div className="inbox-flex">
                                <p className="inbox-p">Cumulated rate of<br/>return</p>
                                <p className="inbox-num">461.21%</p>
                            </div>
                            <div className="inbox-flex">
                                <p className="inbox-p">Annualized rate of<br/>return</p>
                                <p className="inbox-num">115.30%</p>
                            </div>
                            <div className="inbox-flex">
                                <p className="inbox-p">MDD</p>
                                <p className="inbox-num">6.93%</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="box4 aos-init" data-aos="zoom-in-up" data-aos-duration="1500">
                    <div className="in">
                        <p className="title-box">Leo Alpha BM3</p>
                        <p className="title-sub">futures trading</p>
                        <div className="inbox-line">
                            <div className="inbox-flex">
                                <p className="inbox-p">Cumulated rate of<br/>return</p>
                                <p className="inbox-num">454.25%</p>
                            </div>
                            <div className="inbox-flex">
                                <p className="inbox-p">Annualized rate of<br/>return</p>
                                <p className="inbox-num">113.56%</p>
                            </div>
                            <div className="inbox-flex">
                                <p className="inbox-p">MDD</p>
                                <p className="inbox-num">12.18%</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <p className="flexcontainer_underp">
                위 수익률은 2018.01.01 ~ 2021.12.31까지의 결과입니다.
            </p>
            <div className="service-box2">
                <p className="leoalpha_font">Algorithm trend analysis</p>
                <p className="big-p">Algorithm that divides the rise and fall of charts and determines the timing of sales</p>
                <div className="leoalpha_div2">
                    <div className="leoalpha_flex">
                        <img src={trickCheck} alt="체크 아이콘" />
                        <p className="line-m">The range of increase and decrease is analyzed by referring to the chart data.</p>
                    </div>
                    <div className="leoalpha_flex">
                        <img src={trickCheck} alt="체크 아이콘" />
                        <p>Algorithms that can be applied to various digital assets.</p>
                    </div>
                </div>
            </div>
            <div className="gridcontainer">
                <div className="flexcontainer2">
                    <div className="box1 aos-init" data-aos="zoom-in-up" data-aos-duration="1500">
                        <div className="in">
                            <p className="title-box">Yield compared to Bitcoin<br/> Holding.</p>
                            <p className="title-sub">Spot transaction only</p>
                            <div className="inbox-line">
                                <div className="inbox-flex">
                                    <p className="inbox-p">Cumulated rate of<br/>return</p>
                                    <p className="inbox-num">48.23%</p>
                                </div>
                                <div className="inbox-flex">
                                    <p className="inbox-p">Monthly rate of<br/>return</p>
                                    <p className="inbox-num">12.05%</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <p className="flexcontainer_underp2">
                ※ All figures are the results of transaction details from May 1, 2022 to August 30, 2022.
            </p>
            <p id="target3" className="line"></p>
        </section>
    );
};

export default Sirius;