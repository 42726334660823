// src/components/Dantaro.js
import React from 'react';
import dantarologo from '../assets/images/dantarologo.png'
import dantaro1 from '../assets/images/dantaro1.svg'
import dantaro1_m from '../assets/images/dantaro1_m.png'
import dantaro_checl from '../assets/images/dantaro_checl.svg'

const Dantaro = () => {
  const openLink = (url) => {
    window.open(url, '_blank');
  };

  return (
    <div className="Dantaro">
      <div className="dantaro">
        <div className="div-img">
          <img src={dantarologo} className="dantaro-service" alt="Dantaro" />
        </div>
        <p className="dantaro-t">단타로</p>
        <p><span className="dantaro-colort">추세분석 기반의 재테크 서비스</span></p>
        <div className="dantaro_box">
          <div className="dantaro-b2b">
            <p>B2B</p>
          </div>
          <div className="dantaro-b2b dantaro_go" onClick={() => openLink('https://portal.gmc-labs.com')}>
            <p>단타로 바로가기</p>
          </div>
        </div>
        <div className="grid-service">
          <div className="grid-display">
            <div className="img_wrap">
              <img src={dantaro1} className="mobi_none" alt="Dantaro" />
              <img src={dantaro1_m} className="desk_none" alt="Dantaro Mobile" />
            </div>
            <div className="flex-divline">
              <div className="flex-div">
                <img src={dantaro_checl} alt="Check" />
                <p>글로벌 거래소 OKX와 서비스 제휴</p>
              </div>
              <div className="flex-div">
                <img src={dantaro_checl} alt="Check" />
                <p>매수 알고리즘과 매도 알고리즘, 두 가지의 알고리즘 존재</p>
              </div>
              <div className="flex-div">
                <img src={dantaro_checl} alt="Check" />
                <p>시세 하락폭과 상승폭에 따라 자동으로 알고리즘을 전환</p>
              </div>
              <div className="flex-div">
                <img src={dantaro_checl} alt="Check" />
                <p>알고리즘 자동 전환을 통해 손실은 최소화, 지속적인 수익 실현 가능</p>
              </div>
              <div className="flex-div">
                <img src={dantaro_checl} alt="Check" />
                <p>쉽고 간단한 사용자 지향형 UI</p>
              </div>
              <div className="flex-div">
                <img src={dantaro_checl} alt="Check" />
                <p>반응형 UI로 모바일로도 간편하게 사용</p>
              </div>
            </div>
          </div>
          <div className="grid-under">
            <div className="flex-underbox">
              <p>차트 지표 분석</p>
            </div>
            <div className="flex-underbox">
              <p>API 연동</p>
            </div>
            <div className="flex-underbox">
              <p>안정적인 수익</p>
            </div>
            <div className="flex-underbox">
              <p>간단한 사용법</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dantaro;