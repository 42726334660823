// src/components/Dfians.js
import React from 'react';
import '../styles/dfians.css'; // 스타일 파일을 따로 만들어서 import 하세요.
import dfianslogo from '../assets/images/dfianslogo.png'
import Dfiansimg from '../assets/images/Dfiansimg.svg'
import Dfiansimg_m from '../assets/images/Dfiansimg_m.png'
import difians_check from '../assets/images/difians_check.svg'

const Dfians = () => {
    return (
        <div className="Dfians">
            <div className="Dfians-div">
                <div className="div-img">
                    <img src={dfianslogo} alt="Dfians 서비스" className="difians-service" />
                </div>
                <p className="difians-t">월렛 서비스</p>
                <p><span className="difians-colort mb_font">자산운용 시스템이 연동된 수익형 지갑 서비스</span></p>
                <div className="difians-b2b">
                    <p>B2B</p>
                </div>
                <div className="grid-service">
                    <div className="grid-display">
                        <div className="img_wrap">
                            <img src={Dfiansimg} alt="Dfians 이미지" className="mobi_none" />
                            <img src={Dfiansimg_m} alt="Dfians 모바일 이미지" className="desk_none" />
                        </div>
                        <div className="flex-divline">
                            <div className="flex-div">
                                <img src={difians_check} alt="체크 아이콘" />
                                <p>디지털 자산 예치 서비스가 결합된 수익형 지갑</p>
                            </div>
                            <div className="flex-div">
                                <img src={difians_check} alt="체크 아이콘" />
                                <p>자체 자산운용 시스템을 통해 정기적인 이자 수익 제공</p>
                            </div>
                            <div className="flex-div">
                                <img src={difians_check} alt="체크 아이콘" />
                                <p>안전한 자산 보관 및 편리한 관리</p>
                            </div>
                        </div>
                    </div>
                    <div className="grid-under">
                        <div className="flex-underbox">
                            <p>자산운용 시스템 연동</p>
                        </div>
                        <div className="flex-underbox">
                            <p>예치 서비스</p>
                        </div>
                        <div className="flex-underbox">
                            <p>상시 가입</p>
                        </div>
                        <div className="flex-underbox">
                            <p>안정적인 이자 수익</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dfians;