import React from 'react';

const Footer = () => {
  return (
    <footer id="footer">
      <div id="footerIn">
        <div className="finfo">
          <p><span>주식회사</span> 지엠씨랩스 | <span>대표</span> : 권세영</p>
          <p><span>사업자 등록번호</span> : 467-87-01727</p>
          <p>부산광역시 남구 전포대로 133 WEWORK 12F</p>
          <p>help@gmc-labs.com</p>
        </div>
        <hr />
        <div className="copy">
          Copyright © GMC LABS Inc. ALL RIGHTS RESERVED.
        </div>
      </div>
    </footer>
  );
};

export default Footer;