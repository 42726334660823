import React from 'react';
import { Carousel as ReactCarousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const CustumCarousel = ({ slides }) => {
  console.log(slides)
  return (
    <ReactCarousel
      showArrows={true}
      autoPlay={true}
      interval={10000}
      infiniteLoop={true}
      wrapAround={true}
      showThumbs={false}
    >
      {slides.map(slide => (
        <div key={slide.id} className="carousel__item">
          <img src={require(`../assets/images/${slide.src}`)} alt='test' className="msImg" />
          {/* 이미지 대신 alt 텍스트만 표시 */}
        </div>
      ))}
    </ReactCarousel>
  );
};

export default CustumCarousel;