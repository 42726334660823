import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './styles/style.css';
import Home from './pages/Home';
import Service from './pages/Service'
import News from './pages/News';
import HomeEng from './pages/HomeEng';
import ServiceEng from './pages/ServiceEng';
import NewsEng from './pages/NewsEng';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Service" element={<Service />} />
        <Route path="/News" element={<News />} />
        <Route path="/home_en" element={<HomeEng />} />
        <Route path="/Service_en" element={<ServiceEng />} />
        <Route path="/News_en" element={<NewsEng />} />
        {/* <Route path="*" element={<Navigate to="/" />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
