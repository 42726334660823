import React from 'react'
import '../../styles/Members.css'
import daniel from '../../assets/images/daniel.png'
import steve from '../../assets/images/steve.png'
import yang from '../../assets/images/yang.png'

const MemberCard = ({ name, photo, description }) => {
    return (
        <div className="member-card">
            <img src={photo} alt={name} />
            <h3>{name}</h3>
            <div>
            {description.map((desc, index) => (
                <p key={index}>{desc}</p> // 각 항목을 <p>로 출력
            ))}
            <br/>
            </div>
        </div>
    );
}

function Members() {
    return (
        <div id="members">
            <div className="members_img">
            </div>
            <h4 className="section_title">Members</h4>
            <div className="members_wrap">
                <MemberCard name="Daniel Kwon" photo={daniel} description={["Digital asset investment planning", "Trading system planning, algorithm", "Consulting for blockchain-based service"]}/>
                <MemberCard name="Steve Yoo" photo={steve} description={["Algorithm system development", "Automated trading algorithm development"]} />
                <MemberCard name="Kyungseok Yang" photo={yang} description={["Head of Dantaro Service Operations", "Management and education of investment trading systems"]} />
            </div>
        </div>
    )
}

export default Members
