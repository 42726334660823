// src/components/Bonego.js
import React from 'react';
import '../../styles/Bonego.css'; // 스타일 파일을 따로 만들어서 import 하세요.
import bonegologo from '../../assets/images/bonegologo.png'
import bonego1 from '../../assets/images/bonego1.svg'
import bonego1M from '../../assets/images/bonego1_m.png'
import bonegoCheck from '../../assets/images/bonego_check.svg'

const Bonego = () => {
  return (
    <div className="bonego">
      <div className="bonego-div">
        <div className="div-img">
          <img src={bonegologo} alt="보내고 서비스" className="bonego-service" />
        </div>
        <p className="bonego-t"></p>
        <p class="sub_title_wrap"><span className="bonego-colort">Blockchain-based MultichannelTransaction System</span></p>
        <div className="bonego-b2b">
          <p class="mobi_font">Business partnership</p>
        </div>
        <div className="grid-service">
          <div className="grid-display">
            <div className="img_wrap">
              <img src={bonego1} alt="보내고 서비스 이미지" className="mobi_none" />
              <img src={bonego1M} alt="보내고 모바일 서비스 이미지" className="desk_none" />
            </div>
            <div className="flex-divline">
              <div className="flex-div">
                <img src={bonegoCheck} alt="체크 아이콘" />
                <p>All-in-one wallet service from international exchange to remittance and payment</p>
              </div>
              <div className="flex-div">
              <img src={bonegoCheck} alt="체크 아이콘" />
                <p>Direct payment in KRW from overseas: automatic exchange</p>
              </div>
              <div className="flex-div">
              <img src={bonegoCheck} alt="체크 아이콘" />
                <p>Easy payment with money exchanged</p>
              </div>
              <div className="flex-div">
              <img src={bonegoCheck} alt="체크 아이콘" />
                <p>Transfer immediately after exchanging money</p>
              </div>
              <div className="flex-div">
              <img src={bonegoCheck} alt="체크 아이콘" />
                <p>Busan city -Hana card Fintech Challenge Award-winning Item</p>
              </div>
            </div>
          </div>
          <div className="grid-under">
            <div className="flex-underbox">
              <p>Simple currency exchange</p>
            </div>
            <div className="flex-underbox">
              <p>Simple overseas remittance</p>
            </div>
            <div className="flex-underbox">
              <p>Simple overseas payment</p>
            </div>
            <div className="flex-underbox">
              <p>Blockchain-based system</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bonego;