import React from 'react';

const partners = [
  { link: 'https://www.busan.go.kr/news/index', className: 'cf-img01' },
  { link: 'https://license.korcham.net/indexmain.jsp', className: 'cf-img01' },
  { link: 'https://www.pusan.ac.kr/kor/Main.do', className: 'cf-img01' },
  { link: 'http://www.bnkfintechlab.com/', className: 'cf-img01' },
  { link: 'http://finpc.org/', className: 'cf-img02' },
  { link: 'https://fintech.or.kr/web/user/main.do', className: 'cf-img02' },
  { link: 'http://m.dongbu.co.kr/', className: 'cf-img02' },
  { link: 'https://www.sba.seoul.kr/', className: 'cf-img02' },
  { link: 'https://www.vstro.net/', className: 'cf-img03' },
  { link: 'https://www.hiblocks.io/', className: 'cf-img03' },
  { link: 'https://www.okx.com/join/PHUONGKIEU', className: 'cf-img03' },
  { link: 'https://www.hanacard.co.kr/OPH30000000N.web?_frame=no&schID=pcd&mID=OPH30000000C&', className: 'cf-img03' },
  { link: 'https://alyn75.wixsite.com/twinning1', className: 'cf-img04' },
  { link: 'https://kpowerkorea.com/', className: 'cf-img04' },
  { link: 'https://thebarum.co.kr/', className: 'cf-img04' },
  { link: 'https://www.upchain.kr/', className: 'cf-img04' },
];

const PartnerList = () => {
  return (
    <section class="cont4">
    <div class="inner">
      <h4 class="section_title">
        Partners
      </h4>

      <div class="ptbox">
      {['cf-img01', 'cf-img02', 'cf-img03', 'cf-img04'].map((className) => (
        <ul className={`cf ${className}`} key={className}>
          {partners
            .filter(partner => partner.className === className)
            .map((partner, index) => (
              <li
                key={index}
                data-aos="flip-left"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="2000"
                data-aos-offset="300"
                className="aos-init aos-animate"
                onClick={() => window.open(partner.link)}
              >
              </li>
            ))}
        </ul>
      ))}
              </div>
        </div>
      </section>
  );
};

export default PartnerList;