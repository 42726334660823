import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // useHistory 대신 useNavigate로 변경
import AOS from 'aos'; // AOS 임포트
import 'aos/dist/aos.css'; // AOS CSS 임포트
import '../styles/home.css'
import '../styles/header.css'
import '../styles/common.css'
import '../styles/reset.css'
import '../styles/topbtn.css'
import CarouselComponent from '../components/CustumCarousel';
import PartnerList from '../components/Partners';
import Header from '../components/Header';
import AboutUsEn from "../components/eng/AboutUsEn";
import MissionVisionEn from "../components/eng/MissionVisionEn";
import HistoryEn from "../components/eng/HistoryEn";
import AffiliateEnSection from "../components/eng/AffiliateEn";
import MembersEn from "../components/eng/MembersEn";
import FooterEn from "../components/eng/FooterEn";

const HomeEng = () => {
  const navigate = useNavigate(); // useHistory를 useNavigate로 변경
  const sectionTop = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [scrollActive, setScrollActive] = useState(false);
  const isMobile = window.innerWidth <= 768;

  const [slides] = useState([
    { id: 1, src: 'bannermain.jpg' },
    { id: 2, src: 'main_img2.jpg' },
    { id: 3, src: 'main_img3.jpg' },
  ]);
  const [mbslides] = useState([
    { id: 1, src: 'mb_bannermain.jpg' },
    { id: 2, src: 'mb_main_img2.jpg' },
    { id: 3, src: 'mb_main_img3.jpg' },
  ]);

  useEffect(() => {
    AOS.init();
    const handleScroll = () => {
      setScrollActive(window.scrollY > 800);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const dantaroUrl = () => {
    window.open('https://portal.gmc-labs.com/', '_blank');
  };

  const moreBnt = () => {
    navigate('/Service'); // history.push 대신 navigate로 변경
  };

  return (
    <>
      <Header openMenu={openMenu} setOpenMenu={setOpenMenu} />
      <div id="main" className="main">
        <div id="mainVisual">
          <div className="mainSlider en">
            <CarouselComponent slides={isMobile ? mbslides : slides} />
            <div className="tbox">
              <div className="blur_box"></div>
              <div className="ms_text_wrap" style={{ display: 'inline-block' }}>
                <img src={require('../assets/images/bannerLogo.svg').default} alt="GMC Labs" className="mobi_none" />
                <img src={require('../assets/images/bannerLogo_m.png')} alt="GMC Labs" className="desk_none" />
                <h4 className="main_title">
                  Through Digital Asset Financial Technology,<br />easy and simple for everyone<br />to add value to your life.
                </h4>
                <p className="sub_title en">
                  GMC LABS is creating a new financial world with digital asset based systems.
                </p>
              </div>
            </div>
            <button className="go_dantaro_btn" onClick={dantaroUrl}>
              Go to Dantaro →
            </button>
            <button type="button" className="ms_down_icon" onClick={() => {
              sectionTop.current.scrollIntoView({ behavior: 'smooth' }); // AboutUs 섹션으로 부드럽게 스크롤
            }}>
              <img src={require('../assets/images/down-circle-outline.svg').default} alt="down_icon" />
            </button>
          </div>
        </div>
        <div id="container">
          <div ref={sectionTop}></div>
          <AboutUsEn moreBnt={moreBnt} />
          <MissionVisionEn />
          <HistoryEn />
          <AffiliateEnSection />
          <MembersEn />
          <PartnerList />
        </div>
        <FooterEn />
        <button
          className={`btn_top_display ${scrollActive ? 'on' : ''}`}
          onClick={toTop}
        />
      </div>
    </>
  );
};

export default HomeEng;