// src/components/ChartSection.js
import React from 'react';
import '../../styles/ChartSection.css'; // 스타일 파일을 따로 만들어서 import 하세요.
import trick_check from '../../assets/images/trick_check.svg'

const ChartSection = ({ id }) => {
    return (
        <section className="chart" id={id}>
            <div className="section_title_box">
                <p className="section_title">Trading system</p>
            </div>
            <div className="display_grid">
                <div>
                    <p className="leoalpha_font">Asset Management System</p>
                    <p className="big-p">Asset Management System Combining Self-Developed Algorithms</p>
                    <div className="trading-line">
                        <div className="trading_flex">
                            <img src={trick_check} alt="체크 아이콘" />
                            <p className="line-m">Organization of personal and institutional asset management systems</p>
                        </div>
                        <div className="trading_flex">
                            <img src={trick_check} alt="체크 아이콘" />
                            <p className="line-m">Support for various exchanges is available</p>
                        </div>
                        <div className="trading_flex">
                            <img src={trick_check} alt="체크 아이콘" />
                            <p className="line-m">Portfolio constructed with a variety of digital asset operations</p>
                        </div>
                        <div className="trading_flex">
                            <img src={trick_check} alt="체크 아이콘" />
                            <p className="line-m">Easy and simple user oriented UI.</p>
                        </div>
                        <div className="trading_flex">
                            <img src={trick_check} alt="체크 아이콘" />
                            <p className="line-m">Reactive UI makes it easy to use on mobile.</p>
                        </div>
                    </div>
                </div>
                <div>
                    <p className="leoalpha_font">Provice Liquidity System</p>
                    <p className="big-p">Provision of liquidity for foundations and exchanges.</p>
                    <div className="trading-line">
                        <div className="trading_flex">
                            <img src={trick_check} alt="체크 아이콘" />
                            <p className="line-m">Use detailed settings easily and simply</p>
                        </div>
                        <div className="trading_flex">
                            <img src={trick_check} alt="체크 아이콘" />
                            <p className="line-m">Simple financial transactions between exchanges</p>
                        </div>
                        <div className="trading_flex">
                            <img src={trick_check} alt="체크 아이콘" />
                            <p className="line-m">Apply algorithms to respond to bot attacks</p>
                        </div>
                        <div className="trading_flex">
                            <img src={trick_check} alt="체크 아이콘" />
                            <p className="line-m">User-friendly UI with interface from exchange</p>
                        </div>
                        <div className="trading_flex">
                            <img src={trick_check} alt="체크 아이콘" />
                            <p className="line-m">Easy to use on mobile with responsive UI</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ChartSection;